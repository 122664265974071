
  .writtingText {
    font-family: 'League Script', cursive;
  }

@media (max-width: 735px) {

  body {
    font-family: "Roboto", sans-serif;
  }


  .mainDiv {
    /* transition: scale; */
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(right, #3A6073, #16222A);
    background: linear-gradient(to left, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      font-size: 16px;
      color: #AEB6BF;
      min-height: 100%;
      padding-bottom: 2vh;
    }

    .projectImages {
      width: 13vh;
      height: 13vh;
    }

    .fade {
      color: #5D6D7E;
    }

    h1, h2, h3 {
    font-size: 22px;
    font-weight: normal;
    color: white;
    font-family: "Roboto", sans-serif;
    }

    h4 {
      font-size: 18px;
      font-family: "Roboto", sans-serif;
    }

    a.CColor {
      font-size: 18px;
      color: white;
      font-family: "Roboto", sans-serif;
    }

    span.CColor {
      color: #AEB6BF;
      font-size: 14px;
  }

  .innerDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 7vh;
    padding-bottom: 2vh;
  }

  /* Separator Line */

  hr.fancy-line {
  border: 0;
  height: 1px;
  position: relative;
  margin: 0.2em 0; /* Keep other elements away from pseudo elements*/
  }
  hr.fancy-line:before {
      top: -0.5em;
      height: 1em;
  }
  hr.fancy-line:after {
      content:'';
      height: 0.5em;   /* half the height of :before */
      top: 1px;        /* height of hr*/
  }

  hr.fancy-line:before, hr.fancy-line:after {
      content: '';
      position: absolute;
      width: 100%;
  }

  hr.fancy-line, hr.fancy-line:before {
      background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.1)), color-stop(75%,rgba(0,0,0,0)));
      background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
      background: -webkit-radial-gradient(center, ellipse, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
      background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
  }


}


@media (min-width: 735px) {

  .myHeader {
    align-items: center;
    justify-content: center;
  }


  body {
    display: block;
    min-height: 100%;
    background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(right, #3A6073, #16222A);
    background: linear-gradient(to left, #3A6073, #16222A);
    font-family: "Roboto", sans-serif;
  }

  .mainDiv {

      background: #16222A;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to left, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
      background: -webkit-linear-gradient(right, #3A6073, #16222A);
      background: linear-gradient(to left, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      height: 560px;


      font-size: 16px;
      color: #AEB6BF;
    }

    .rightDiv {
      position: absolute;
      left: 50%;
      width: 35%;
      padding-bottom: 30px;;

    }

    .projectImages {
      min-width: 10vh;
      min-height: 10vh;
    }


    h1, h2, h3, h4 {
    font-weight: normal;
    color: white;
    font-family: "Roboto", sans-serif;

    }


    a.CColor {
      font-size: 18px;
      color: white;
    }

    span.CColor {
      color: #AEB6BF;
      font-size: 14px;
  }

    .fade {
      color: #5D6D7E;
    }

    .innerDiv {
      text-align: center;
    }


   .leftDiv {
      position: absolute;
      top: 30%;
      bottom: 0;
      right: 58%;
      width: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }


    /* Separator Line */

    hr.fancy-line {
    border: 0;
    height: 1px;
    position: relative;
    margin: 0.5em 0; /* Keep other elements away from pseudo elements*/
    margin-top: 0px;
    margin-bottom: 0px;
    }
    hr.fancy-line:before {
        top: -0.5em;
        height: 1em;
    }
    hr.fancy-line:after {
        content:'';
        height: 0.5em;   /* half the height of :before */
        top: 1px;        /* height of hr*/
    }

    hr.fancy-line:before, hr.fancy-line:after {
        content: '';
        position: absolute;
        width: 100%;
    }

    hr.fancy-line, hr.fancy-line:before {
        background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.1)), color-stop(75%,rgba(0,0,0,0)));
        background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
        background: -webkit-radial-gradient(center, ellipse, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
    }




}

